import React, { Component } from "react";
import { navigate } from "gatsby";
import Cookies from "js-cookie";
import Grid from "@material-ui/core/Grid";
import Fade from "@material-ui/core/Fade";
import Slide from "@material-ui/core/Slide";
import Navbar from "./components/layout/navbar";
import Header from "./components/layout/Header";
import SubHeaderDesc from "./components/layout/SubHeaderDesc";
import Button from "@material-ui/core/Button";

class einFail extends Component {
  componentDidMount() {
    Cookies.remove("filingReason");
    Cookies.remove("email");
    Cookies.remove("firstName");
    Cookies.remove("lastName");
    Cookies.remove("phone");
    Cookies.remove("ssn");
    Cookies.remove("addressLine1");
    Cookies.remove("addressLine2");
    Cookies.remove("city");
    Cookies.remove("state");
    Cookies.remove("zipcode");
    Cookies.remove("value");
    Cookies.remove("invalidAddress");
    Cookies.remove("entityName");
    Cookies.remove("entityType");
    Cookies.remove("membersNumber");
    Cookies.remove("entityState");
    Cookies.remove("businessCategory");
  }

  render() {
    return (
      <div>
        <Navbar />
        <div style={{ margin: 20 }}>
          <Fade in={true} timeout={1200}>
            <Slide
              direction="up"
              in={true}
              mountOnEnter
              unmountOnExit
              timeout={500}
            >
              <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justify="center"
              >
                <Header>EIN Request Failed</Header>
                <div style={{ maxWidth: 500, textAlign: "center" }}>
                  <SubHeaderDesc>
                    We were unable to obatin an EIN for you immediately.
                  </SubHeaderDesc>
                  <SubHeaderDesc>
                    A full refund has been placed for your order. You can try
                    submitting a new application with different information for
                    a successful filing.
                  </SubHeaderDesc>
                </div>
                <br />
                <Button
                  onClick={() => navigate("/get-ein/start")}
                  variant="contained"
                  color="primary"
                  size="large"
                  buttonstyle={{ borderRadius: 25 }}
                  style={{
                    borderRadius: 25,
                    textTransform: "none",
                    width: 300,
                    alignItems: "center",
                    justify: "center"
                  }}
                >
                  Submit a New Application
                </Button>
              </Grid>
            </Slide>
          </Fade>
        </div>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
    );
  }
}

export default einFail;
